
// import { create, has } from "../../api/tag.js";
import { pinyin } from "pinyin-pro";
import { defineComponent } from "vue";
import { client } from '@/client';
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "tag-add",
  components: {},
  data: () => {
    return {
      params: {
        //接口入参
        name: "",
        path: "",
      },
      paramsRules: {
        //校验规则
        name: [
          { required: true, message: "请输入栏目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "名称长度在 2 到 50 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "params.name": function (newv, oldv) {
      this.params.path = pinyin(newv, { toneType: "none" }).replace(/\s+/g, "");
    },
  },
  methods: {
    //新增
    async create() {
      try {
        let res = await client.callApi("tag/Add",{
          tag_name:this.params.name,
          tag_path:this.params.path
        });
        if (res.isSucc) {
          ElMessage({
            message: "新增成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async has() {
      try {
        let res = await client.callApi("tag/Has",this.params);
        if (res.isSucc) {
            ElMessage({
              message: "标签已存在，请不要再添加了",
              type: "success",
            });
        } else {
          this.create();
        }
      } catch (error) {
        console.log(error);
      }
    },
    submit(formName: string) {
      let refs:any = this.$refs[formName];

      refs.validate((valid: any) => {
        if (valid) {
          this.has();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
