import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-10 ml-10 mb-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "params",
      model: _ctx.params,
      rules: _ctx.paramsRules,
      "label-width": "84px"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_form_item, {
            label: "标签名称",
            prop: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.name) = $event)),
                placeholder: "请输入汉字"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "标签标识" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.params.path,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.path) = $event)),
                placeholder: "模板使用标识",
                disabled: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit('params')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("保存")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}